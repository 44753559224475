@use "fonts.scss";

@keyframes page-out {
    to {
        opacity: 0;
    }
}

@keyframes page-in {
    from {
        opacity: 0;
    }
}

$fontSizeHuge: 72px;
$fontSizeLarge: 32px;
$fontSizeNormal: 16px;
$fontSizeSmall: 12px;
$mobileBreakpoint: 800px;

// Declare dark theme first, just in case browser doesn't support
// prefers-color-scheme media query
:root {
    --background-rgb: 8, 8, 8;
    --foreground-rgb: 255, 255, 255;
    --background-color: rgb(var(--background-rgb));
    --foreground-color: rgb(var(--foreground-rgb));
    --accent-hue: 200;
    --accent-color: hsl(var(--accent-hue), 100%, 60%);
    --table-padding: 16px;
}

// LIGHT THEME
@media (prefers-color-scheme: light) {
    :root {
        --background-rgb: 255, 255, 255;
        --foreground-rgb: 8, 8, 8;
        --accent-color: hsl(var(--accent-hue), 100%, 35%);
    }
}

// DARK THEME (default)
@media (prefers-color-scheme: dark), (prefers-color-scheme:no-preference) {
    :root {
        --background-rgb: 8, 8, 8;
        --foreground-rgb: 255, 255, 255;
        --accent-color: hsl(var(--accent-hue), 100%, 60%);
    }
}

a {
    color: var(--accent-color);
    transition: color 200ms ease-in-out;
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
    max-height: 640px;
    object-fit: scale-down;
}

iframe {
    display: block;
    margin: 0 auto;
    width: 720px;
    max-width: 100%;
    height: 400px;
}

video {
    width: 100%;
    height: auto;
    max-height: 640px;
}

p {
    line-height: 1.8;
    margin: 16px 0;
}

ul > li, ol > li {
    line-height: 1.8;
}

code {
    font-family: 'Cascadia Code', monospace;

    :not(pre) > & {
        background-color: rgba(var(--foreground-rgb), 0.1);
        padding: 2px 4px;
    }
}

pre {
    overflow: auto;
    padding: 8px;
    background-color: rgba(var(--foreground-rgb), 0.1);
    border-left: 2px solid rgba(var(--foreground-rgb), 0.2);
}

div.subtitle {
    font-size: $fontSizeSmall;
    font-weight: 400;
}

body.out {
    animation-name: page-out;
    animation-duration: 200ms;
    animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190); /* easeInCubic */
    animation-fill-mode: forwards;
}

body.in {
    animation-name: page-in;
    animation-duration: 200ms;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000); /* easeOutCubic */
    animation-fill-mode: forwards;
}

body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Inter var", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: $fontSizeNormal;
    color: var(--foreground-color);
    background-color: var(--background-color);

    @media (min-width: $mobileBreakpoint)
    {
        margin: 32px;
    }

    > main {
        width: 100%;
        max-width: 1200px;
        padding-bottom: 20vh;

        h2, h3 {
            margin: 16px 0;
        }

        > header {
            > h2 {
                margin: 0;
                font-size: $fontSizeNormal;
                font-weight: 500;
            }
            > h1 {
                margin: 0;
                font-size: $fontSizeHuge;
                font-weight: 700;
            }
        }

        > section {
            &:first-child {
                > header {
                    font-size: $fontSizeHuge;
                    font-weight: 700;
                    margin-bottom: 32px;
                }
            }

            &:not(:last-child) {
                margin-bottom: 32px;
            }

            > header {
                font-size: $fontSizeLarge;
                font-weight: 700;
            }

            > ul {
                list-style-type: none;
                margin: 0 -16px;
                padding: 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                @media (max-width: $mobileBreakpoint)
                {
                    flex-direction: column;
                }

                > li {
                    display: block;
                    width: calc(100% - 32px);
                    margin: 16px;

                    @media (min-width: $mobileBreakpoint)
                    {
                        width: calc(29% - 32px);
                    }

                    > header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        line-height: normal;

                        > svg {
                            margin-right: 8px;
                        }

                        > div.text {
                            > div {
                                font-size: 12px;
        
                                &:first-child {
                                    font-size: 22px;
                                }
                            }
                        }
                    }

                    > p {
                        margin: 4px 0;
                    }
                }

                > li:last-child {
                    display: flex;
                    align-items: center;

                    @media (min-width: $mobileBreakpoint)
                    {
                        width: calc(13% - 32px);
                    }

                    a {
                        display: flex;
                        flex-grow: 1;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        font-size: 22px;
                        flex-direction: column;

                        @media (max-width: $mobileBreakpoint)
                        {
                            flex-direction: row-reverse;
                        }
                    }
                }
            }
        }

        > article {
            &:first-of-type {
                margin-top: 16px;
            }

            > header {
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    margin-right: 8px;
                }

                > div > h1 {
                    margin: 0;
                    font-size: 24px;
                    font-weight: 700;
                }
            }

            > p {
                margin: 4px 0;
            }

            &:not(:last-child)
            {
                margin-bottom: 32px;
            }
        }
    }
}

table {
    & > thead > tr, & > tbody > tr {
        th:first-child, td:first-child {
            padding: var(--table-padding) var(--table-padding) var(--table-padding) 0;
        }

        th:last-child, td:last-child {
            padding: var(--table-padding) 0 var(--table-padding) var(--table-padding);
        }

        th:not(:first-child):not(:last-child), td:not(:first-child):not(:last-child) {
            padding: var(--table-padding);
        }
    }
}
